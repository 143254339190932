export default {
    props: {
        transformer: { type: Array }
    },

    data: () => ({
        pageItems: null,
        oldPageIds: null,
        totalRows: -1,
        isFetching: false,
        internalTransformer: null,
        oldFilter: null,
        onFirstSync: false
    }),

    computed: {
		requestId() {
			return `table-${this._uid}`
		},
		hasOnlinegroupbyPagination() {
			return !this.internalTransformer && this.hasPaginationSelect && !!this.group_by
		},
		totalRowsTrad() {
			// En online, si on a un group by non paginé, ou un affichage classique
			if(!this.internalTransformer && !this.hasOnlinegroupbyPagination) {
				if(this.computed_items.length === 0) return ''
				else if(this.computed_items.length === 1) return `1 ${this.getTrad('table.general.resultat')}`
				return `${this.computed_items.length} ${this.getTrad('table.general.resultats')}`
			}

			else if(this.totalRows < 1) return ''
			else if(this.totalRows === 1) return `1 ${this.getTrad('table.general.resultat')}`
			return `${this.totalRows} ${this.getTrad('table.general.resultats')}`
		}
	}
}