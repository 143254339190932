import Table from '@/mixins/Table'
import Common from '@/assets/js/common'
import Vue from 'vue'

export function applyFilters(items, filters, groupByCustom = {}) {
	if (!Array.isArray(filters)) {
		filters = Object.values(filters)
	}

	filters.forEach(filter => {
		items = items.filter(item => {
			return isValidItem(item, filter, groupByCustom)
		})
	})

	return items
}

export function isValidItem(item, filter, groupByCustom = {}) {
	if (typeof filter === 'function') {
		return filter(item)
	}

	let item_value = Common.getNestedObjectString(item, filter.column)
	
    if(Object.prototype.hasOwnProperty.call(groupByCustom, filter.column)) {
        item_value = groupByCustom[filter.column](item_value)
    }

    let filter_value = filter.value

	// si la colonne termine par _date il faut transformer la valeur
	if(filter.column.endsWith('_date') && !(filter_value instanceof Date)) {
		if (item_value !== '' && (typeof item_value === 'string')) {
			const date_formatted = Date.parseTz(item_value)
			if(date_formatted !== 'Invalid Date') {
				item_value = date_formatted
			}
		}

		if(filter_value instanceof Array && typeof filter_value[0] == 'string') {
			const date_formatted_filter = Date.parseTz(filter_value)
			if(date_formatted_filter !== 'Invalid Date') {
				filter_value = date_formatted_filter
			}
		}
	}

	if(filter.column == 'formatted_invoices' && item_value) {
		const fact_tab = item_value.map(item => item.invoice_num)
		item_value = fact_tab.join('-')
	}

	if(filter.column.endsWith('_tags') && item_value) {
		item_value = item_value.map(color => color.tags_color).join(',')
	}

	if(filter.column == 'all_client_account' && item_value) {
		const account_number = item_value.map(item => item.tiersclientaccount_number)
		item_value = account_number.join('-')
	}

	if(item_value === undefined && filter_value) {
		return false
	}
	
	return Table.methods[filter.operator](item_value, filter_value)
}
