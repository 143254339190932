import Tools from '@/mixins/Tools.js'

export default {
    mixins: [Tools],
    methods: {
        /**
         * Télécharges des données au format CSV
         * @param {Array} ls liste d'objets JS
         * @param {Object} headers objet ayant le label pour clé et la clé à récupérer en valeur. Ex: { "Mon header de colonne" : ma_cle }
         * @param {String} [filename=export.csv] nom du fichier à télécharger
         * @param {String} [delimiter=;] caractère qui délimite les colonnes
         */
        downloadJsonToCsv(ls, headers, filename, delimiter) {
            delimiter = delimiter || ';'

            let rows = [
                Object.values(headers)
            ]

            ls.forEach(item => {
                let row = []
                Object.keys(headers).forEach(key => {
                  let value = this.getNestedObjectString(item, key)
                  value = (value === undefined || value === null) ? '' : value
                  row.push(`"${value}"`)
                })
                rows.push(row)
            })

            this.downloadCsv(rows, filename, delimiter)
        },

        /**
         * Télécharges des données au format CSV
         * @param {Array} rows liste de lignes à télécharger en CSV
         * @param {String} [filename=export.csv] nom du fichier à télécharger
         * @param {String} [delimiter=;] caractère qui délimite les colonnes
         */
        downloadCsv (rows, filename, delimiter) {
          filename = filename || 'export.csv'
          delimiter = delimiter || ','
          const csvRows = []
          const regex = /<br\s*[/]?>/gi;
          rows.forEach(row_array => {
            csvRows.push(row_array.join(delimiter).replace(regex, ""))
          })

          let csvContent = csvRows.join('\r\n')
          csvContent = csvContent.replace(/\./gm, ',')
          this.downloadData(csvContent, filename)
        },
  
        /**
         * Télécharge des données sous forme de chaîne de caractères
         * @param {String} data dataURI à télécharger
         * @param {String} filename nom du fichier à télécharger
         */
        downloadData (fileContent, fileName, type="application/vnd.ms-excel", charset="UTF-16LE") {
          if (window.navigator.msSaveBlob) { // IE
            const blob = new Blob([fileContent], {type:  `${type};charset=${charset};`});
            window.navigator.msSaveOrOpenBlob(blob, fileName)
          } else {
            const encodedUri = `data:${type}; charset=${charset},${encodeURIComponent('\uFEFF' + fileContent)}`

            const link = document.createElement('a')
            link.setAttribute('href', encodedUri)
            link.setAttribute('target', '_blank');
            link.setAttribute('download', fileName);

            link.click() // This will download the data file named "my_data.csv".
            link.remove()
          }
        },
    }
}
