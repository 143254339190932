<template>
    <div class="position-relative">
        <div v-if="display_action_button" class="table-actions">
            <div class="row align-items-center align-items-start-md">
                <div class="col pr-0">
                    <!-- Le champs de recherche -->
                    <b-button
                        v-if="filtre_general"
                        v-b-toggle="`collapse-search-${this._uid}`"
                        class="btn btn-secondary"
                        @click="searchFocus()"
                    >
                        <font-awesome-icon :icon="['fal', 'search']" /> <span class="d-none d-lg-inline">{{ $t('global.rechercher') }}</span>
                    </b-button>

                    <!-- Le bouton de filtres de dates -->
                    <div
                        v-if="dateFilter"
                        class="input-group d-md-inline-flex align-middle w-auto order-first d-none"
                    >
                        <div class="input-group-prepend">
                            <div class="btn input-group-text" @click="dateFilterToday" v-b-tooltip.hover="$t('table.general.reset_to_today')">
                                <font-awesome-icon :icon="['fal', 'calendar']" />
                            </div>
                        </div>

                        <input
                            readonly
                            class="form-control"
                            v-b-toggle="`collapse-dates-${this._uid}`"
                            v-model="dateRangeLabel"
                        >

                    </div>

                    <div
                        v-if="dateFilter"
                        class="d-inline-flex d-md-none align-middle w-auto order-first"
                    >

                            <div class="btn input-group-text" v-b-toggle="`collapse-dates-${this._uid}`" >
                                <font-awesome-icon :icon="['fal', 'calendar']" />
                            </div>
                    </div>

                    <div
                        v-if="userAccess.hasConfTableAccess && hasConfTableAccess"
                        class="input-group d-inline-flex align-middle w-auto order-first"
                    >
                        <div class="input-group-prepend">
                            <!-- Le bouton de gestion des vues -->
                            <div
                                class="btn input-group-text"
                                @click="goToViewEditor"
                            >
                                <font-awesome-icon :icon="['fal', 'cogs']" />
                            </div>
                        </div>
                        <!-- Le menu déroulant de sélection des vues -->
                        <b-form-select
                            v-model="selectedView"
                            class="custom-select"
                            ref="views-select"
                        >
                            <option v-for="(selectOption, indexOpt) in views" :key="indexOpt" :value="selectOption">
                                {{ selectOption.text }}
                            </option>
                        </b-form-select>
                    </div>                   

                    <!-- Affichage en mode grid/list -->
                    <div
                        v-if="mediaField"
                        class="toggleGrid btn btn-secondary"
                    >
                        <b-form-checkbox
                            :checked="display_mode === 'grid'"
                            :class="display_mode"
                            switch
                            @change="toggleDisplayMode"
                        >
                            <font-awesome-icon
                                :icon="['fal', 'list']"
                                :class="{
                                    isActive: display_mode === 'list'
                                }"
                            />
                            <font-awesome-icon
                                :icon="['fal', 'th']"
                                :class="{
                                    isActive: display_mode === 'grid'
                                }"
                            />
                        </b-form-checkbox>
                    </div>

                    <!-- Nombre de résultats à afficher -->
                    <div class="btn btn-seconday" v-if="hasPaginationSelect && showPagination">
                        <b-form-select
                            v-model="elementsPerPageModel"
                            class="custom-select"
                            ref="pagination-select"
                        >
                            <option v-for="(selectNb, indexNb) in elementsInPages" :key="indexNb" :value="selectNb">
                                {{ selectNb }}
                            </option>
                        </b-form-select>
                    </div>
                    <button v-if="alertFilter && arrayAlertViewPerso.length > 0" @click="showAlertFilter" class="alert-warning btn btn-sm" >
                        <font-awesome-icon :icon="['fal', 'exclamation-triangle']" style="color: #8F5922;"/> 
                    </button>
                </div>
                <div v-if="!hideOperationActions" class="col-auto ml-auto pl-0 mb-1 mb-md-0">
                    <Action
                        v-for="button in primaryButtons"
                        :key="button.button_id"
                        v-show="button.minimum_selected <= selected.length || button.minimum_selected === undefined"
                        :name="button.name"
                        :icon="button.icon"
                        :className="button.className"
                        :allow_access="button.allow_access"
                        :confirm_message="button.modal_options ? button.modal_options.text_message : ''"
                        :confirm_title="button.modal_options ? button.modal_options.text_title : ''"
                        :confirm_question="button.modal_options ? button.modal_options.text_question : ''"
                        :confirm_button_ok="button.modal_options ? button.modal_options.text_button_ok : ''"
                        :confirm_button_cancel="button.modal_options ? button.modal_options.text_button_cancel : ''"
                        :action="button.action"
                        :selected_fn="getSelected"
                        :spin="button.spin"
                        :button_action_event_only="buttonActionEventOnly"
                        :uniqueEventId="uniqueEventId"
                        :dropdown_name="button.dropdown_name"
                    />
                </div>
            </div>
        </div>

        <!-- Champs de recherche -->
        <b-collapse
            v-if="filtre_general"
            :id="`collapse-search-${this._uid}`"
        >
            <TableSearchInput
                ref="searchfield"
                :searchColumn.sync="ctx.filter.global.column"
                :searchOperator.sync="ctx.filter.global.operator"
                :searchValue.sync="ctx.filter.global.value"
                :columns="columns"
            />
        </b-collapse>

        <!-- Filtrage par date -->
        <b-collapse
            :id="`collapse-dates-${this._uid}`"
            v-if="refresh_date_range && dateFilter"
        >
            <DateRangeV2
                v-if="dateRangeV2"
                :start-label="dateFilter.startLabel"
                :end-label="dateFilter.endLabel"
                :start.sync="dateRange.start"
                :end.sync="dateRange.end"
                @submit="onDateRangeSubmit"
                :start-default="dateFilter.start"
                :end-default="dateFilter.end"
                :persistId="persistDateFilter ? id_table : ''"
                :dateSelect=true
                :periodToShow="periodToShow"
            />
            <DateRange
                v-else
                :start-label="dateFilter.startLabel"
                :end-label="dateFilter.endLabel"
                :start.sync="dateRange.start"
                :end.sync="dateRange.end"
                @submit="onDateRangeSubmit"
                :start-default="dateFilter.start"
                :end-default="dateFilter.end"
                :persistId="persistDateFilter ? id_table : ''"
            />
        </b-collapse>
        <div class="row align-items-center" v-if="showPagination">
            <div class="col-12 col-md-auto mr-auto text-sm-left text-center">
                <span
                    v-if="transformer"
                    v-b-tooltip.hover="this.$t('storage.index_table')"
                    class="btn btn-link d-none"
                    @click="indexTable"
                >
                    <font-awesome-icon :icon="['fas', 'atom-alt']" />
                </span>
                {{ totalRowsTrad }}
            </div>
            <!-- pagination -->
            <div class="col-12 col-md-auto text-sm-right text-center"  v-if="showPagination">
                <b-pagination
                    v-model="ctx.currentPage"
                    :total-rows="totalRows === -1 ? computed_items.length : totalRows"
                    :per-page="ctx.perPage"
                    limit="10"
                    class="mb-0"
                    aria-controls="my-table"
                    @page-click="resetCheckAll"
                >
                </b-pagination>
            </div>
        </div>
        <!-- Zone de la table-->
        <h5 v-if="isIndexing">
            {{ $t('storage.indexation.table_is_indexing') }}
        </h5>
        <b-alert show variant="warning" class="mt-2" v-if="messageAlertFilter && arrayAlertViewPerso.length > 0">
			{{ $t('table.general.alerte_filtres') }}
            <ul v-for="filtre, index in arrayAlertViewPerso" :key="index">
                <li class="mb-n3">
                    {{filtre}}
                </li>
            </ul>
		</b-alert>

        <div v-show="localBusy || (synchroIsWorkingOnCurrentModel && onFirstSync)">
            <LoadingSpinner class="col-12" v-if="synchroIsWorkingOnCurrentModel && onFirstSync" text_trad="table.general.synchro_working" />
            <LoadingSpinner class="col-12" v-else />
        </div>

        <ProgressBar
            :style="{
                'visibility': isFetching ? 'visible' : 'hidden'
            }"
        />

        <div
            ref="customTable"
            :class="(this.selected.length > 0 ? 'hasOneActive' : 'hasNoneActive') + ' table_' + id_table"
            v-show="!localBusy && !(synchroIsWorkingOnCurrentModel && onFirstSync)"
        >
            <div v-if="display_mode === 'grid' && mediaField !== null">
                <TableCards
                    :primaryKey="primaryKey"
                    :items="computed_items"
                    :fields="fields.slice(1, 4)"
                    :mediaField="mediaField"
                    :rowToEditSlots="rowToEditSlots"
                    :previewColClass="previewColClass"
                    :autoConstructUrl="autoConstructUrl"
                    @row-clicked="rowSelect"
                />
            </div>
            <div v-else class="table-responsive">
                <!-- Version standard -->
                <b-table
                    v-if="group_by === ''"
                    striped hover borderless show-empty caption-top
                    @row-clicked="rowSelect"
                    :items="computed_items"
                    :fields="fields"
                    :per-page="ctx.perPage"
                    :current-page="transformer ? 1 : ctx.currentPage"
                    :tbody-tr-class="rowClass"
                    :tbody-transition-props="transProps"
                    :primary-key="primaryKey"
                    :checkbox-header="true"
                    :empty-text="getTrad('global.no_result')"
                    :empty-filtered-text="getTrad('global.no_result')"
                    :sort-by.sync="ctx.sortBy"
                    :sort-desc.sync="ctx.sortDesc"
                    :sort-compare="customSort"
                    no-sort-reset
                    no-select-on-click
                >
                    <!-- Le modèle pour les headers -->
                    <template v-slot:head()="data" :v-show="computed_items && computed_items.length > 0 && !busy">
                        <a @click="setCheckedAll(isCheckedAll = !isCheckedAll)" :class="isCheckedAll ? 'checkAll checkedAll' : 'checkAll'" v-if="data.column == '[checkbox]'" :id="'tooltip-target-' + id_table"></a>
                        <span v-else>{{ data.label }}</span>    
                        <b-tooltip id="click_all_lines" v-if="showCheckAllTip" placement="right" variant="primary" :show="showCheckAllTip" :target="'tooltip-target-' + id_table">
                            <a href="#" style="color:white;" @click.prevent="selectAllPages()">{{ $t('global.select_all_question') }}</a>
                        </b-tooltip>
                    </template>

                    <template v-slot:cell()="data">
                        <DefaultCell :item="data" />
                    </template>

                    <!-- Le modèle de checkbox pour les lignes data.rowSelected -->
                    <template v-slot:cell([checkbox])="data">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" v-model="data.item.isActive" >
                            <label class="custom-control-label"></label>
                        </div>
                    </template>

                    <template v-slot:cell([fake_checkbox])>
                        <div class="custom-control custom-checkbox"></div>
                    </template>

                    <!-- La colonne de prévisualisation par défaut -->
                    <template v-slot:cell([preview])="data">
                        <div :class="[{'custom-control': !overridePreviewSlot }, previewColClass, `${previewColClass}+__default`]">
                            <font-awesome-icon
                                v-if="!overridePreviewSlot"
                                :class="[previewColClass, previewColClass+'__default']"
                                :icon="['fas', 'pencil-alt']"
                            />
                            <slot
                                v-else
                                name="custom-slot-preview"
                                v-bind:data="data.item"
                            ></slot>
                        </div>
                    </template>

                    <!-- Gestion des slots customs - appliquer des paramètres customs sur une colonne de la config de table -->
                    <template
                        v-for="(customConf, col_name) in rowToEditSlots"
                        v-slot:[col_name]="data"
                    >
                        <!-- Extern slot only -->
                        <div
                            v-if="customConf.externSlot"
                            :key="col_name"
                        >
                            <!-- Si on souhaite customiser des slots en dehors du customtable -->
                            <slot :name="`custom-slot-${col_name}`" v-bind:data="data.item"></slot>
                        </div>

                        <ItemCell
                            v-else
                            :key="col_name"
                            :data="data"
                            :customConf="customConf"
                            :colName="col_name"
                            :previewColClass="previewColClass"
                            :autoConstructUrl="autoConstructUrl"
                        />
                    </template>

                    <!-- slot en fonction du columns_types dans le "TableConfigSeeder" -->
                    <template
                        v-for="(customConf, col_name_bis) in slotTypes"
                        v-slot:[col_name_bis]="data"
                    >
                        <!-- columns_type  monnaie -->
                        <div :key="col_name_bis" v-if="customConf.columnType == 'monnaie'">
                            <PriceFormat
                                :pathArr="data.item"
                                :price="data.item[customConf.cell]"
                                :currency="customConf.currency"
                                :symbol="true"
                            />
                        </div>
                        <div :key="col_name_bis" v-if="customConf.columnType == 'number'">
                            <NumberFormat
                                :number="data.item[customConf.cell]"
                            />
                        </div>
                    </template>

                    <!-- switch (exemple: triggers actifs/inactifs) -->
                    <template v-slot:cell([switch])="data">
                        <div class="custom-control custom-checkbox">
                            <b-form-checkbox name="check-button" switch v-model="data.item.switch" @change="getSwitched(data.item.id, !data.item.switch)">
                                <template v-if="data.item.switch">
                                    {{ switchOn }}
                                </template>
                                <template v-else>
                                    {{ switchOff }}
                                </template>
                            </b-form-checkbox>
                        </div>
                    </template>

                    <!-- row details button -->
                    <template v-slot:cell(show_details)="row">
                        <RowDetailsButton :row="row" :columns="columns" />
                    </template>

                    <!-- row details content -->
                    <template v-slot:row-details="row">
                        <RowDetails :row="row" :columns="columns" :edit-slots="rowToEditSlots">
                            <!-- On établit un pont pour les slots externes -->
                            <template v-for="col_name in externSlotColumns"
                                v-slot:[`custom-slot-cell(${col_name})`]="{ data }"
                            >
                                <slot :name="`custom-slot-cell(${col_name})`" v-bind:data="data.item">rre</slot>
                            </template>
                        </RowDetails>
                    </template>
                </b-table>

                <!-- Si on a un group by, on affiche les sous tables -->
                <template v-if="group_by !== ''">
                    <!-- Entêtes -->
                    <b-table
                        hover borderless fixed
                        ref="customTableHeader"
                        :items="() => []"
                        :fields="fields"
                        :tbody-tr-class="rowClass"
                        primary-key="primaryKey"
                        :empty-text="getTrad('global.no_result')"
                        :empty-filtered-text="getTrad('global.no_result')"
                        :checkbox-header="true"
                        no-local-sorting
                        class="table-sub-header"
                    >
                        <template slot="table-busy">
                            <LoadingSpinner class="col-12" />
                        </template>

                        <!-- Le modèle pour les headers -->
                        <template v-slot:head()="data" :v-show="computed_items && computed_items.length > 0 && !busy">
                            <a @click="setCheckedAll(isCheckedAll = !isCheckedAll)" :class="isCheckedAll ? 'checkAll checkedAll' : 'checkAll'" v-if="data.column == '[checkbox]'" :id="'group-tooltip-target-' + id_table"></a>
                            <span v-else>{{ data.label }}</span>    
                            <b-tooltip id="click_all_lines" v-if="showCheckAllTip" placement="right" variant="primary" :show="showCheckAllTip" :target="'group-tooltip-target-' + id_table">
                                <a href="#" style="color:white;" @click.prevent="selectAllPages()">{{ $t('global.select_all_question') }}</a>
                            </b-tooltip>
                        </template>
                    </b-table>

                    <!-- Sous tableau regroupés par le group_by -->
                    <SubTable
                        v-show="!busy"
                        v-for="(lines, group) in computed_items_group"
                        :key="group"
                        :items="lines"
                        :fields="fields"
                        :columns="columns"
                        :primary_key="primaryKey"
                        :group_by="group_by"
                        :group_val="group"
                        :select_mode="selectMode"
                        :per_page="ctx.perPage"
                        :current_page="1"
                        :row_clicked="rowGroupSelect"
                        :row-class="rowClass"
                        :has_checkboxes="checkboxes"
                        :sort_by="ctx.sortBy"
                        :sort_desc="ctx.sortDesc"
                        :tbody_transition_props="transProps"
                        :empty_text="getTrad('global.no_result')"
                        :head_colspan="columnQuantity"
                        :liens="liens"
                        :autoConstructUrl="autoConstructUrl"
                        :rowToEditSlots="rowToEditSlots"
                        :slotTypes="slotTypes"
                        :previewColClass="previewColClass"
                        :switch="switchComputed"
                        :switchOn="switchOn"
                        :switchOff="switchOff"
                        :callback_switch="callback_switch"
                        :groupByCustom="groupByCustom"
                    >
                        <template
                            v-for="(customConf, col_name) in rowToEditSlots"
                            v-slot:[`custom-slot-${col_name}`]="data"
                        >
                            <div :key="col_name">
                                <slot :name="`custom-slot-${col_name}`" v-bind="data" />
                            </div>
                        </template>    
                    </SubTable>

                    <!-- Le message si table vide -->
                    <div class="text-center" v-show="Object.keys(computed_items_group).length == 0 && !busy">
                        <i>{{ getTrad('global.no_result') }}</i>
                    </div>
                </template>
            </div>
        </div>

        <!-- Les éléments de pagination -->
        <div class="row" v-if="showPagination">
            <div class="col-12 col-md-auto text-sm-right text-center ml-md-auto">
                <b-pagination
                    v-model="ctx.currentPage"
                    :total-rows="totalRows === -1 ? computed_items.length : totalRows"
                    :per-page="ctx.perPage"
                    limit="10"
                    aria-controls="my-table"
					@page-click="resetCheckAll"
                >
                </b-pagination>
            </div>
        </div>

        <div v-if="isBelowMd" class="mt-5"><br></div>

        <TableActions
            v-if="display_action_button && !hideOperationActions"
            :buttons="buttons_enabled"
            :selected="selected"
            :getSelected="getSelected"
            :buttonActionEventOnly="buttonActionEventOnly"
            :uniqueEventId="uniqueEventId"
            :hasExportOption="hasExportOption"
            :isExporting.sync="isExporting"
            @onExportCsvVirgule="onExportCsv(',')"
            @onExportCsvPointVirgule="onExportCsv(';')"
            @onExportExcel="onExportExcel"
            @unselectAll="unselectAll"
        />
    </div>
</template>

<style type="text/css">
    .table-responsive .td_checkbox{
        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
    }

    .table-secondary {
        display: none;
    }
</style>

<script type="text/javascript">
import Vue from 'vue'
import { EventBus } from 'EventBus'
import Tools from '@/mixins/Tools.js'
import TableMixin from '@/mixins/Table.js'
import OfflineTableMixin from '@/mixins/OfflineTable.js'
import CsvMixin from '@/mixins/Csv.js'
import Navigation from '@/mixins/Navigation.js'
import Window from '@/mixins/Window.js'
import { applyFilters } from 'GroomyRoot/assets/js/utils/table'
import Buttons from '@/assets/js/buttons'

import _difference from 'lodash/difference';
import _merge from 'lodash/merge'
import _orderBy from 'lodash/orderBy'
import _replace from 'lodash/replace'
import _isString from 'lodash/isString'
import _debounce from 'lodash/debounce'
import _sortBy from "lodash/sortBy"

export default {
    name: 'CustomTable',
    mixins: [Tools, TableMixin, Navigation, CsvMixin, OfflineTableMixin, Window],

    components: {
        PriceFormat: () => import('GroomyRoot/components/Format/PriceFormat'),
        NumberFormat: () => import('GroomyRoot/components/Format/NumberFormat'),
        LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
        TableActions: () => import('GroomyRoot/components/Table/TableActions'),
        TableCards: () => import('GroomyRoot/components/Table/CustomTableCards'),
        Action: () => import('GroomyRoot/components/Buttons/TableAction'),
        SubTable: () => import('GroomyRoot/components/Table/CustomSubTable'),
        DefaultCell: () => import('GroomyRoot/components/Table/DefaultCell'),
        ItemCell: () => import('GroomyRoot/components/Table/ItemCell'),
        RowDetailsButton: () => import('GroomyRoot/components/Table/RowDetailsButton'),
        RowDetails: () => import('GroomyRoot/components/Table/RowDetails'),
        TableSearchInput: () => import('GroomyRoot/components/Table/TableSearchInput'),
        DateRange: () => import('GroomyRoot/components/Inputs/DateRange'),
        DateRangeV2: () => import('GroomyRoot/components/Inputs/DateRangeV2'),
        ProgressBar: () => import('GroomyRoot/components/Utils/ProgressBar'),
    },
    props: {
        id_table: String, /* Identifiant unique de la table, pour repérer dans quel écran on se trouve. Exemple : liste_actes. */
        items: { type: Array, default : () => ([]) }, /* La liste des items à afficher
            Une array d'arrays :
            [
                [
                    'link' : Le lien si l'élément est un lien hypertexte. null par défaut.
                    'isActive' : Définit si l'élément est sélectionné ou non. Généré si n'existe pas. Ex : true / false
                ]
            ,...]
        */
        primaryKey: String, /* Index d'un champ unique dans items (fortement recommandé, par exemple un _id) */
        callback: { type: Function, default: () => {} }, /* Facultatif - Fonction callback appelée en cas de clic */
        callback_one: { type: Function, default: () => {} }, /* Facultatif - Fonction callback appelée en cas de clic */
        callback_one_deselect: { type: Function, default: () => {} }, /* Facultatif - Fonction callback appelée en cas de clic */
        callback_switch: { type: Function, default: () => {} },
        selected_ids: { type: Array, default: () => []},
        busy: { type: Boolean, default: false },/* Facultatif - Définit si le tableau est en cours de chargement ou non. Ex : true / false */
        preselected_items: {type: Array, default: () => ([]) },/* Facultatif - Liste d'éléments similaires à items, représentant les éléments préselectionnés lors de l'ouverture du tableau */
        selectMode: { type: String, default: () => ('multi') }, /* Facultatif - Mode de sélection : 'multi', 'single' (si table groupée : 1 sélection / groupe) ou 'range' */
        elementsPerPage: { type: Number, default: () => (50) }, /* Facultatif - Nombre de lignes par page */
        limit: { type: Number, default: () => (null)}, /* Facultatif - Nombre maximum de résultats dans le tableau */
        checkboxes: {type: Boolean, default: () => (true)}, /* Facultatif - Afficher ou masquer les checkbox (true / false) */
        display_action_button: {type: Boolean, default: () => (true)}, /* Facultatif - Afficher ou masquer les bouttons d'actions, filtres, export, et nombre d'éléments par page */
        hideOperationActions: { type: Boolean, default: false }, /* Masquer ou non, uniquement les boutons d'action (ajouter/modifier/valider/...) */
        filtre_general: { type: Boolean, default: () => (true)}, /* Facultatif - Afficher ou masquer le filtre général */
        hide_if_empty: { type: Boolean, default: () => (false)}, /* Facutatif - Masque tout le composant s'il est vide (utile pour les tables-enfants) */
        hrefsRoutes: { type: Object, default: null }, /* Objet création de liens, sur une ligne
            'nom_colonne_clickable': {
                routeName: 'nomRouteVueRouter',
                params: {
                    paramRoute_1: val1,
                    paramRoute_2: val2
                }
            }
         */
        mediaField: { type: String, default: null },
        colCallback: { type: Object, default: null }, /* Objet permettant de définir une callback, au clic sur un champs
            'nom_colonne_clickable': callback() => au clic sur le champs, on appelle la callback
        */
        previewColumn: { type: Function, default: null }, /* Si définit, au clic sur le champs, la callback est appelée avec l'item en param. Ajoute également la classe previewColClass sur les td des colonnes définies */
        customPreviewColumn: { type: Array, default: null }, /* Si définit, ajoute la classe previewColClass sur les td des colonnes définies dans cet array */
        overridePreviewSlot: { type: Boolean, default: false }, /* Si on souhaite override le slot de la colonne de preview par défaut (celle activée par la présence de customPreviewColumn) */
        rawColumns: {type: Array, default: () => []}, /* Liste optionnelle de colonnes sur lesquelles on peut envoyer du html brut (mais pas de composant vue) */
        externSlotColumns: {type: Array, default: () => []}, /* Liste optionnelle permettant d'établir des slots, extensibles dans le composant de déclaration du customtable */
        groupByCustom: {type: Object, default: () => {}}, /* Tableau d'objet avec en clé une colonne et en valeur une méthode appelée pour le formattage de la donnée dans le groupby */
        rowSelectable: {type: Boolean, default: true},
        hasExportOption: {type: Boolean, default: true},
        hasConfTableAccess: {type: Boolean, default: true},
        hasPaginationSelect: {type: Boolean, default: true},
        dateFilter: { type: [Boolean, Object], default: false}, /* column, start, end */
        switch: {type: Boolean, default: false}, /* exemple dans components/Triggers/List */
        switchOn: String,
        switchOff: String,
        buttonActionEventOnly: {type: Boolean, default: false}, /* Si l'on souhaite avoir des events à la place des redirections classiques, sur les clic de boutons d'actions */
        uniqueEventId: { type: String, default: '' }, /* Si plusieurs tableaux sur une même vue, avec des events identiques à écouter, permet de ne pas avoir de conflit. Les events seront suffixés de ce prop */
        columsAdd: {type: Array, default: () => []}, /* Si l'on souhaite ajouter des colonnes dynamiquement */
        columsAddBegin: {type: Array, default: () => []}, /* Si l'on souhaite ajouter des colonnes dynamiquement au début du tableau */
        selectItemsPolicy: { type: Function, default: () => false }, /* Callback appelée pour effectuer des traitements supplémentaires au traitement du clic dans le CustomTable */
        baseFilters: { type: Object, default: () => {} }, /* Filtres à appliquer aux items */
        persistDateFilter: { type: Boolean, default: false }, /* Si passé à true, le DateRange enregistre les dates dans vuex */
        showPagination: { type: Boolean, default: true }, /* Affiche ou non la pagination */
        dateRangeV2: {type: Boolean, default: false }, /* On utilise le dateRangeV2 ou le DateRange */
        periodToShow: {type: Array, default: null }, /* Si on utilise le dateRangeV2, permet de choisir quelles périodes afficher */
        //numberCol: { type: Object, defaut: () => {} }, /* liste des colonnes de type Number pour le tri et l'export (ex: invoice_ttc: invoice_ttc_vanilla) */
    },
    data() {
        return {
            columnType: {},
            alertFilter : false,
            messageAlertFilter: false,
            arrayAlertViewPerso: [],
            isCheckedAll: false,
            test: false,
            isExporting: false,
            elementsInPages: [
                10,
                50,
                100,
                200,
                500,
                1000,
            ],
            elementsPerPageModel: 50,
            ctx: {
                sortBy: null,
                sortDesc: false,
                currentPage: 1,
                /* Filtres à appliquer
                Une objet d'objets :
                    {
                        nom_du_filtre: {
                            column : String,
                            operator : String,
                            value : String
                        }
                        ...
                    }
                */
                filter: {
                    global: {
                        column: 'stringified',
                        operator: 'mustContain',
                        value: ''
                    }
                },
                perPage: 10,
                columns: [],
            },
            preselected_lines: [], /* Les lignes préselectionnées (mises en cache depuis la prop pour permettre la remise à zéro) */
            transProps: { name: 'table-transition' },/* classe CSS pour l'effet de transition */
            views: [], /* Tableau des vues personnalisées pour ce tableau */
            selectedView: null, /* La vue sélectionnée */
            nLines: 0, /* Pour les tables parent uniquement : Nombre d'éléments affichés */
            columns: [], /* La configuration des colonnes à afficher
                Une array d'arrays :
                [
                    [
                        'label' : Le titre affiché de la colonne. Ex : $t('Supprimer')
                        'key' : le nom de la colonne dans this.computed_items. Ex : 'horse_nom'
                        'sortable' : Définit si l'élément peut être utilisé comme ordre de tri. Ex : true / false
                        'filtrable' : Définit si l'élément peut être utilisé comme filtre. Ex : true / false
                    ]
                ,...]
            */

            dateRange: {},
            buttons: [],
            group_by: '', /* Element par lequel on peut grouper les tables, et obtenir un ensemble de sous tables */
            selected: [],
            switched: [],
            previewColClass: 'previewColIdentifier', /* Classe servant uniquement à identifier les éléments cliquable, ouvrant une preview (sans traitement du b-table) */
            tableRowVariant: true, /* Si à false, le clic sur une ligne du tableau d'affectera pas l'affichage (par défaut, une classe active s'ajoute) */

            debouncedRefreshTable: null, // Fonction refreshTable "debounced",
            display_mode: null,
            computed_items: [],
            numberCol: [],
            lastPageID: null,
            lastIdSelected: null,
            refresh_date_range: true
        }
    },

    /* Initialisation du tableau */
    created: function() {
        this.init_view()
    },
    beforeDestroy() {
        EventBus.$emit('NavMobile::HideNav', `table-${this._uid}`)
    },

    methods: {
    	init_view() {
    		let views = []
    		/* Récupération des configurations */
	        const config = this.getConfig('table_config')
	        /* Chargement des configurations par défaut */
	        config[this.id_table][0].forEach( view => {
	            views.push({
	                value : view,
	                is_user: false,
	                text : this.getTrad('table.general.default_view'),
	                group_by: view.group_by || '',
	                default_view: false,
	                id : 0,
	                name : this.id_table
	            })
	        })

	        let has_config_user = false

	        /* Chargement des configurations utilisateurs */
	        config[this.id_table][1].forEach( view => {
	            has_config_user = true
	            views.push({
	                value : view,
	                is_user: true,
	                text : view.libelle,
	                group_by: view.group_by ? view.group_by : '',
	                default_view: view.default ? true : false,
	                id : view.id,
	                name : this.id_table
	            })
	        })

	        this.views = views

	        this.elementsPerPageModel = this.elementsPerPage
	        const config_per_page = this.getConfig("table-" + this.id_table)

	        if(config_per_page != null && this.elementsInPages.indexOf(config_per_page) > -1)
	        {
	            this.elementsPerPageModel = config_per_page
	        }
	        this.ctx = {
	            sortBy: this.ctx.sortBy,
	            sortDesc: this.ctx.sortDesc,
	            currentPage: 1,
	            filter: this.ctx.filter,
	            perPage: this.elementsPerPageModel,
	            limit: this.limit,
	            columns: []
	        }

	        if(has_config_user) {
	            this.selectedView = this.views.find(view => { return view.default_view == true })

	            if(!this.selectedView) {
	                this.selectedView = this.views[0] // Par défaut, ne charger que la première configuration
	            }
	        }
	        else {
	            this.selectedView = this.views[0] // Par défaut, ne charger que la première configuration
	        }

	        if(Buttons[this.id_table] && Object.keys(Buttons[this.id_table].length > 0)) {
	            this.buttons = Buttons[this.id_table]
	        }

            if(this.views[0].value['columns_types'] && this.views[0].value['columns_types']['currency']) {
                this.numberCol = Object.entries(this.views[0].value['columns_types']['types']).filter(col => col[1] == 'monnaie').map(col => col[0])
            }

	        this.debouncedRefreshTable = _debounce(this._refreshTable, 100)

            this.dateRange = this.dateFilter
    	},

        searchFocus() {
            setTimeout(() => {
                this.$refs.searchfield.focus()
            })
        },

        /* Chargement de la vue : les colonnes du tableau d'après l'id_table, et les filtres depuis la base de données */
        loadView: function() {
            if(!this.selectedView || !this.selectedView['value']) return

            /* Chargement des colonnes */
            this.columns = []
            this.selectedView['value']['columns'].forEach(column => {
                if(this.rawColumns.includes(column)) {
                    this.columns.push({
                        label : this.getTrad('table.'+this.id_table+'.'+column),
                        key : column,
                        sortable : this.group_by === '',
                        filtrable : true,
                        rawHtml: true
                    })
                }
                else {
                    this.columns.push({
                        label : this.getTrad('table.'+this.id_table+'.'+column),
                        key : column,
                        sortable : this.group_by === '',
                        filtrable : true,
                        rawHtml: false
                    })
                }
            })

            this.updateFilters()

            this.ctx.sortBy = this.selectedView['value']['sort_by'] || this.group_by || null
            this.ctx.sortDesc = this.selectedView['value']['sort_desc'] || false
            this.ctx.columns = this.selectedView['value']['columns'] || []
            this.ctx.perPage = this.selectedView['value']['displayed_lines'] || 50
            this.elementsPerPageModel = this.ctx.perPage

            const allFilters = this.getConfig('action_filter')
            const table_configs = this.getConfig("table_config")
            const allColumn = table_configs[this.id_table][-1][0].columns
            this.arrayAlertViewPerso = []
            this.messageAlertFilter = false

            if(this.selectedView.value && this.selectedView.value != this.views[0].value) {
                if(this.selectedView.value['filters'].length > 0){
                    this.alertFilter = true
                    for(const filtre of this.selectedView.value['filters']){
                        let filter = ''
                        for(const col of allColumn){
                            if(col == filtre.column ){
                                let columnFilter =  this.getTrad('table.'+this.id_table+'.'+col)
                                filter =  columnFilter
                            }
                        }
                        for(const trad of allFilters){
                            if(trad.action == filtre.critere ){
                                const labelFilter = trad.label.split("_").join(" ")
                                filter += ' ' + labelFilter
                            }
                        }
                        filter += ' ' + filtre.value
                        this.arrayAlertViewPerso.push(filter)
                    }
                }
            } else {
                this.alertFilter = false
            }
        },
        showAlertFilter () {
            this.messageAlertFilter = !this.messageAlertFilter
        },

        async updateComputedItems() {
            let new_items  = this.deppCloneObj(this.pageItems || this.items || [])
            new_items = this.prepareItems(new_items)

            if (this.transformer) {
                this.computed_items = new_items
                return
            }

            this.computed_items = await applyFilters(new_items, this.ctx.filter, this.groupByCustom)
            if(this.ctx.sortBy) {
                if(this.numberCol && this.ctx.sortBy in this.numberCol) {
                    this.computed_items = _orderBy(this.computed_items, this.numberCol[this.ctx.sortBy], this.ctx.sortDesc ? 'desc' : 'asc')
                }
                else {
                    this.computed_items = _orderBy(this.computed_items, this.ctx.sortBy, this.ctx.sortDesc ? 'desc' : 'asc')
                }
            }

            // Si on nécessite une pagination du customtable, sur une requete online, pour un rendu en group by, on fait la pagination à la main
            if(this.hasOnlinegroupbyPagination) {

                let values = Object.values(this.computed_items_group);
                values = values.flat(1);

                let items_sorted = values.filter(v => {
                    return !Object.prototype.hasOwnProperty.call(v, '[group_by]')
                })

                this.totalRows = this.computed_items.length
                const start = this.ctx.currentPage > 1 ? (this.ctx.currentPage-1) * this.ctx.perPage : 0
                const end   = this.ctx.perPage * this.ctx.currentPage
                // this.computed_items = this.computed_items.slice(start, end)
                this.computed_items = items_sorted.slice(start, end)
            }
        },

        /**
         * Retourne les items qui ont déjà été fetch mais
         * qui ne sont pas complètement transformés avec
         * toutes les colonnes. Utilisé pour l'affichage dynamique
         * des boutons d'actions
         */
        getPartialSelected() {
            let items = this.items
            if (this.items.length < this.computed_items.length){
                items = this.computed_items
            }

            return this.selected.map(selected => {
                return items.find(item => {
                    const id = this.getNestedObjectString(item, this.primaryKey)
                    return id == selected
                })
            })
        },

        async getSelected() {
            if (this.transformer) {
                const internalTransformer = await this.getInternalTransformer()
                const tableName = await internalTransformer.getTable()
                let items = await internalTransformer.retrievePage(
                    this.$sync.replaceWithReplicated(tableName, this.selected),
                    {
                        currentPage: 1,
                        perPage: -1,
                        filter: {},
                        sortBy: this.primaryKey,
                        sortDesc: false
                    }
                )
                // je trie les éléments en fonction de leur emplacement dans la liste selected pour garder l'ordre du tableau dans l'export
                items.sort((a,b) => {
                    let id_a = this.getNestedObjectString(a, this.primaryKey)
                    let id_b = this.getNestedObjectString(b, this.primaryKey)

                    return this.selected.indexOf(id_a) - this.selected.indexOf(id_b)
                })
                return items
            }
            else {
                return this.getPartialSelected()
            }
        },

        updateSelected() {
            this.computed_items.forEach(item => {
                const id = this.getNestedObjectString(item, this.primaryKey)
                item.isActive = this.selected.indexOf(id) !== -1
            })
        },

        /* Préselection d'éléments */
        preselectThings: function() {
            this.computed_items.forEach(item => {
                const found = this.preselected_lines.find(lines => this.getNestedObjectString(lines, this.primaryKey) == this.getNestedObjectString(item, this.primaryKey))
                if(this.preselected_lines.indexOf(item) > -1 || found) {
                    const id = this.getNestedObjectString(item, this.primaryKey)
                    this.setRowSelect(id, true)
                }
            })
            this.updateSelected()
        },

        /* Lors de la sélection d'une ligne */
        rowSelect: async function(row, idx, evt) {
            this.$emit('row-select', row)

            // Si c'est une colonne preview qui a été cliquée
            const isPreviewOnly = this.isPreviewRowClicked(evt)

            if(isPreviewOnly) {
                this.tableRowVariant = false
                const colClicked = this.getPreviewColClicked(evt)
                this.previewColumn(row, colClicked)
                return
            }

            if(!this.checkboxes) {
                return
            }

            this.preselected_lines = []

            /* En cas de sélection unique, s'assurer qu'un seul élément est sélectionné */
            if(this.selectMode == 'single') {
                this.selected = []
            }

            if(evt.shiftKey && !row.isActive && this.lastPageID) {
                let ids_to_add = []
                if(this.transformer) {
                    ids_to_add = await this.getTransformerItemsBetween(this.lastPageID, this.ctx, this.lastIdSelected, idx);
                }
                else {
                    const tab = await this.getItemsBetween(idx)
                    ids_to_add = tab.map(el => {
                        return this.getNestedObjectString(el, this.primaryKey)
                    })
                }

                ids_to_add.forEach(id => {
                    this.setRowSelect(id, true)
                })
            }
            this.lastPageID = this.ctx.currentPage
            this.lastIdSelected = idx

            const id = this.getNestedObjectString(row, this.primaryKey)
            this.setRowSelect(id, !row.isActive)
            this.updateSelected()

            this.tableRowVariant = true
        },


        getItemsBetween: async function(idx) {
            let start = 0
            let end
            if (this.ctx.perPage !== -1) {
                start = (this.ctx.perPage * (this.lastPageID - 1)) + this.lastIdSelected + 1
                end = (this.ctx.perPage * (this.ctx.currentPage - 1)) + idx
            }

            if(this.ctx.sortBy) {
                // let items = _sortBy(this.items, [this.ctx.sortBy])
                // if(this.ctx.sortDesc) {
                //     items = items.reverse()
                // }
                // return items.slice(start, end+1)

                let items = await applyFilters(this.deepClone(this.items), this.ctx.filter, this.groupByCustom)
                if(this.ctx.sortBy) {
                    if(this.numberCol && this.ctx.sortBy in this.numberCol) {
                        items = _orderBy(items, this.numberCol[this.ctx.sortBy], this.ctx.sortDesc ? 'desc' : 'asc')
                    }
                    else {
                        items = _orderBy(items, this.ctx.sortBy, this.ctx.sortDesc ? 'desc' : 'asc')
                    }
                }

                return items.slice(start, end+1)
            }
            return this.items.slice(start, end)
        },

        /* Sélection d'une ligne, pour des tableaux groupés */
        rowGroupSelect: function(row, idx, evt) {
            // S'il s'agit de la ligne d'entête
            if(row['[group_by]'] !== undefined) {
                const filters = this.deepClone(this.ctx.filter)
                const columns_types = this.views[0].value['columns_types']['types']

                let label = row['[group_by]']
                if(this.groupByCustom !== undefined && Object.prototype.hasOwnProperty.call(this.groupByCustom, this.group_by)) {
                    // label = this.groupByCustom[this.group_by](label)
                    label = this.tables_groups_brut[this.tables_groups.indexOf(label)]
                }

                if(typeof label == 'number') {
                    label = label.toString()
                }

                let value = columns_types && Object.entries(columns_types).length > 0 ? label.replace(/ \(.*\)/, '') : label

                let match = label.match(/\(.*?\)/g)
                if(match && match.length >= 2) {
                    value += " " + match[0]
                }


                if(this.group_by== 'avenant.type_monte.contract_type_monte.contracttypemonte_label') {
                    value = this.getTrad(value)
                }

                filters.group_filter = {
                    column: this.group_by,
                    operator: 'isEqualTo',
                    value: value
                }

                let items = []
                if(this.transformer === undefined) {
                    items = applyFilters(this.prepareItems(this.items), filters, this.groupByCustom)
                }
                else {
                    items = applyFilters(this.computed_items, filters, this.groupByCustom)
                }

                row.isActive = !row.isActive

                items.forEach(item => {
                    const id = this.getNestedObjectString(item, this.primaryKey)
                    this.setRowSelect(id, row.isActive)
                })
            }
            else {
                const isPreviewOnly = this.isPreviewRowClicked(evt)

                if(isPreviewOnly) {
                    this.tableRowVariant = false
                    const colClicked = this.getPreviewColClicked(evt)
                    this.previewColumn(row, colClicked)
                    return
                }

                this.toggleRowSelect(this.getNestedObjectString(row, this.primaryKey))
            }
            this.updateSelected()
        },

        toggleRowSelect(id) {
            const idx = this.selected.indexOf(id)
            if (idx === -1) {
                this.setRowSelect(id, true)
            }
            else {
                this.setRowSelect(id, false)
            }
        },

        setRowSelect(id, val) {
            // Ajouter l'item aux selected
            // Utiliser le this.items || this.computed_items pour la sélection multiple de pensions
            // par exemple si le callback_one sélectionne une ligne sur une autre page
            const item = (this.items || this.computed_items).find((i) => i[this.primaryKey] === id)
            const idx = this.selected.indexOf(id)
            if (val === true) {
                if (idx === -1) {
                    this.selected.push(id)
                    this.callback_one([item])
                }
            }
            else if (idx !== -1) {
                this.selected.splice(idx, 1)
                this.callback_one_deselect([item])
            }

            this.onRowSelect()
        },

        async onRowSelect() {
            if(this.selected.length > 0) {
                EventBus.$emit('NavMobile::ShowNav', `table-${this._uid}`)
            }
            else {
                EventBus.$emit('NavMobile::HideNav', `table-${this._uid}`)
            }
            this.$emit('update:selected_ids', this.selected)
        },

        async setCheckedAll(val) {
            if(!val) {
                this.selected = []
            }

            if(val && this.items.length > this.computed_items.length) {
                this.infoToast('toast.table_select_first_page')
            }

            // je récupère les lignes de la page en cours
            const start = (this.ctx.currentPage - 1) * this.ctx.perPage
			let selected_items = null
			if(this.transformer === undefined) {
				selected_items = this.computed_items.slice(start, start + this.ctx.perPage)
			}
			else{
				selected_items = this.computed_items.slice(0, start + this.ctx.perPage)
			}

            selected_items.forEach(item => {
                const id = this.getNestedObjectString(item, this.primaryKey)
                this.setRowSelect(id, val)
            })
            this.updateSelected()
        },
        async selectAllPages() {
            this.computed_items.forEach(item => {
                const id = this.getNestedObjectString(item, this.primaryKey)
                this.setRowSelect(id, true)
            })
            this.updateSelected()
        },
        async unselectAll() {
            this.selected = []
            this.updateSelected()
        },
        /* Retourne true si la colonne cliquée est une colonne de preview */
        isPreviewRowClicked(eventClic) {
            if(!this.hasPreviewColumn) return false

            // On récupére la target pour vérifier que l'event ne vienne pas d'un clic sur une preview
            const target = eventClic.target || eventClic.srcElement

            return (target.className && _isString(target.className) && target.className.indexOf(this.previewColClass) > -1) // Si on a l'attribut className (la classe est sur l'élément cliqué)
                // || (target.classList && Array.isArray(target.classList) && target.classList.indexOf(this.previewColClass) > -1)  // Si la classe fait partie des classes attribuées
                // || (target._prevClass && target._prevClass.indexOf(this.previewColClass) > -1) // Si la classe est présente sur le parent (cas du clic sur le composant fontawesome par ex)
                || (this.getNestedObjectString(target, 'parentElement._prevClass') && target.parentElement._prevClass.indexOf(this.previewColClass) > -1) // Si la classe est présente sur le parent (cas du clic sur le composant fontawesome)
        },

        getPreviewColClicked(eventClic) {
            const target = eventClic.target || eventClic.srcElement
            const classPattern = this.previewColClass+'__'

            const retrieveColName = (classes => {
                // On récupére l'index où se trouve le nom de la classe que l'on souhaite traiter
                const classIdx = classes.indexOf(classPattern)
                const classesStartWithTarget = classes.substring(classIdx)

                // On découpe en arrays pour récupérer le nom voulu, qui sera en 1er
                const classesTab = classesStartWithTarget.split(' ')
                const classeTarget = classesTab[0]

                // On nettoie le nom pour ne récupérer que le nom de cellule
                const cellName = classeTarget.split('__')[1]
                const dirtyColName = _replace(cellName, 'cell(', '')

                return _replace(dirtyColName, ')', '')
            })

            // Si la classe spécifique à la colonne est en classe principale
            if(target.className && _isString(target.className) && target.className.includes(classPattern)) {
                return retrieveColName(target.className)
            }
            // Si la classe n'est que sur le parent (utile pour le composant fontawesome)
            else if (this.getNestedObjectString(target, 'parentElement._prevClass') && target.parentElement._prevClass.indexOf(classPattern) > -1) {
                return retrieveColName(target.parentElement._prevClass)
            }

            return ''
        },

        /* Définition de la classe pour les lignes */
        rowClass(item) {
            if(!item) return

            let classes = item.class
            if (item.isActive) {
                classes += ' b-table-row-selected'
            }
            return classes
        },

        /* Aller à l'écran de personnalisation des vues de la table */
        async goToViewEditor() {
            let params = { table_cle : this.id_table, from: this.$route.name }
            this.$store.commit({ type: 'setRedirectParams', params: this.$route.params })

            if(this.isUserView) {
                params.config_id = this.selectedView.id
            }

            this.$router.push({ name: 'tableConfigFilter', params : params })
        },
        dateFilterToday() {
            let end = new Date()
            end.setHours(23,59,59)
            let start = new Date()
            start.setHours(0,0,0)
            this.dateRange = {
                column: 'actes_date',
                start: start,
                end: end
            }
            this.updateFilters()
        },
        prepareItems(items) {
            items.forEach(item => {
                item.isActive = false

                if (!item.stringified) {
                    item.stringified = JSON.stringify(Object.values(item))
                }
            })

            if(!this.hrefsRoutes) return items

            // Pour chaque colonne de liens, on construit les liens de chaque ligne
            const hrefs_names = Object.keys(this.hrefsRoutes)
            if(this.hrefsRoutes && !this.autoConstructUrl) {
                items.forEach(item => {
                    for(let i=0; i<hrefs_names.length; i++) {
                        const el = this.hrefsRoutes[hrefs_names[i]]
                        const params = this.constructRowLinkParams(item, el.params)
                        if(Object.values(params).filter(p => p !== undefined).length > 0) {
                            item['route_'+el.routeUniqueName] = params
                        }
                        else {
                            item['route_'+el.routeUniqueName] = null
                        }
                    }
                })
            }

            return items
        },

        constructRowLinkParams(row, params) {
            let params_ok = {}
            const params_name = Object.keys(params)
            for(let i=0; i<params_name.length; i++) {
                const param_route_name = params_name[i]
                const param_obj_name = params[params_name[i]]

                const param_val = this.getNestedObjectString(row, param_obj_name)
                params_ok[param_route_name] = param_val
            }

            return params_ok
        },

        async getExportItems() {
            return this.getSelected()
        },

        async onExportCsv (delimiter) {
            let numberCol = this.deppCloneObj(this.numberCol)

            if(this.id_table == 'contract' || this.id_table == 'contract_tiers') {
                numberCol = ['articles','fractions.total','fractions.reservation','fractions.ft','fractions.solde1octobre','fractions.soldepv','fractions.solde','fractions.a_facturer','fractions.facture_reservation.encaisse','fractions.facture_reservation.solde','fractions.facture_ft.encaisse','fractions.facture_ft.solde','fractions.facture_1octobre.encaisse','fractions.facture_1octobre.solde','fractions.facture_pv.encaisse','fractions.facture_pv.solde','fractions.facture_saillie.solde']
            }

            this.isExporting = true
            const headers = await this.getExportFields()
            const items = await this.getExportItems()
            const now = new Date()
            const date = now.getDate() + '_' + now.getMonth() + '_' + now.getFullYear() + '_' + now.getHours() + '_' + now.getMinutes()
            this.downloadJsonToCsv(
                items,
                headers,
                `${this.id_table + '_' + date}.csv`,
                delimiter,
                numberCol,
                this.id_table
            )
            this.isExporting = false
            this.setCheckedAll(null)
        },

        async onExportExcel () {
            let numberCol = this.deppCloneObj(this.numberCol)

            if(this.id_table == 'contract' || this.id_table == 'contract_tiers') {
                numberCol = ['articles','fractions.total','fractions.reservation','fractions.ft','fractions.solde1octobre','fractions.soldepv','fractions.solde','fractions.a_facturer','fractions.facture_reservation.encaisse','fractions.facture_reservation.solde','fractions.facture_ft.encaisse','fractions.facture_ft.solde','fractions.facture_1octobre.encaisse','fractions.facture_1octobre.solde','fractions.facture_pv.encaisse','fractions.facture_pv.solde','fractions.facture_saillie.solde']
            }

            this.isExporting = true
            const headers = await this.getExportFields()
            const items = await this.getExportItems()
            const now = new Date()
            const date = now.getDate() + '_' + now.getMonth()+1 + '_' + now.getFullYear() + '_' + now.getHours() + '_' + now.getMinutes()
            this.downloadJsonToExcel(
                items,
                headers,
                `${this.id_table + '_' + date}.xls`,
                numberCol,
                this.id_table
            )
            this.isExporting = false
            this.setCheckedAll(null)
        },

        getExportFields () {
            let fields = {}

            if (this.group_by) {
                fields[this.group_by] = this.$t('filtres.regroupement')
            }

            this.columns.forEach(col => {
                if(this.numberCol && col.key in this.numberCol) {
                    fields[this.numberCol[col.key]] = col.label
                }
                else {
                    fields[col.key] = col.label
                }
            })

            return fields
        },

        manual_check(elems) {
            for (let i = 0; i < elems.length; i++) {
                const id = this.getNestedObjectString(elems[i], this.primaryKey)
                this.setRowSelect(id, true)
            }
            this.updateSelected()
        },

        manual_decheck(elems) {
            for (let i = 0; i < elems.length; i++) {
                const id = this.getNestedObjectString(elems[i], this.primaryKey)
                this.setRowSelect(id, false)
            }
            this.updateSelected()
        },

        onDateRangeSubmit() {
            this.updateFilters()
            this.refreshTable()
        },

        // Doit absolument vider le cache pour détecter les items supprimés
        async refreshTable() {
            await this.resetCachedItems()
            await this._refreshTable()
        },

        /**
         *
         */
        async _refreshTable() {
            if (this.transformer) {
                await this.itemsProvider(this.ctx)
            }
            else {
                this.updateComputedItems()
            }
        },

        updateFilters() {
            // Ajout du filtre général
            let filters = {
                global: this.ctx.filter.global
            }

            // Ajout du filtre des dates
            if (this.dateFilter && this.dateRange) {
                // Récupérer les dates du DateRange au changement de filtre
                if (this.dateRange.start) {
                    this.dateRange.start.setHours(0,0,0,0)
                    filters._date_start = {
                        column: this.dateFilter.column,
                        operator: 'isGreaterOrEqualThan',
                        value: this.dateRange.start
                    }
                    this.setConfig('startDate' + this.id_table, this.dateRange.start)
                }
                if (this.dateRange.end) {
                    this.dateRange.end.setHours(23,59,59)
                    filters._date_end = {
                        column: this.dateFilter.column,
                        operator: 'isLowerOrEqualThan',
                        value: this.dateRange.end
                    }
                    this.setConfig('endDate' + this.id_table, this.dateRange.end)
                }

                this.$emit('update:dateFilter', this.dateRange)
            }

            // Ajout des filtres du composant parent
            if (this.baseFilters) {
                Object.keys(this.baseFilters).forEach((filterName, idx) => {
                    filters[`base_${filterName}`] = this.baseFilters[filterName]
                })
            }

            // Ajout des filtres de la vue
            if(this.selectedView && this.selectedView.value.filters) {
                this.selectedView.value.filters.forEach((filtre, idx) => {
                    filters[`view_${idx}`] = {
                        column : filtre.column,
                        operator : filtre.critere,
                        value : filtre.value
                    }
                })
            }

            this.ctx.filter = filters
        },

        getSwitched(id, val) {
            this.callback_switch(id, val)
        },

        toggleDisplayMode() {
            this.display_mode = (this.display_mode === 'grid' ? 'list': 'grid')
        },

		resetCheckAll(){
			//Au changement de page je décoche le check all sinon je peux pas recocher ceux de la page courante
			this.isCheckedAll = false
		},

        customSort(a, b, key) {
            let el_a = this.getNestedObjectString(a, key)
            if(this.groupByCustom !== undefined && Object.prototype.hasOwnProperty.call(this.groupByCustom, key)) {
                el_a = this.groupByCustom[key](el_a)
            }
            let el_b = this.getNestedObjectString(b, key)
            if(this.groupByCustom !== undefined && Object.prototype.hasOwnProperty.call(this.groupByCustom, key)) {
                el_b = this.groupByCustom[key](el_b)
            }
            if(typeof el_a == 'string') {
                if(el_a === undefined) el_a = ''
                if(el_b === undefined) el_b = ''
                return el_a.localeCompare(el_b)
            }
            if(typeof el_a == 'number') {
                return el_a >= el_b
            }
        },

        updateDateRange() {
            this.dateRange = this.dateFilter
            this.updateFilters()
        }
    },

    computed: {
        slotTypes(){
            let type = {}
            if(this.views[0].value['columns_types'] && this.views[0].value['columns_types']['types']) {           
                for (const [key, value] of Object.entries( this.views[0].value['columns_types']['types'])) {
                    const cell = 'cell('+key+')'
                    type[cell] = {'rawHtml':true, 'columnType':value, 'currency':this.views[0].value['columns_types']['currency'], 'cell':key}
                }
            }
            return type
        },
        dateRangeLabel() {
            let label = ''
            if(this.dateRange && this.dateRange.start) label +=  this.formatDate(this.dateRange.start)
            else label += '∞'
            label += ' - '
            if(this.dateRange && this.dateRange.end) label += this.formatDate(this.dateRange.end)
            else label += '∞'
            return label
        },
        buttons_enabled() {
            const type_actions = this.selected.length > 2 ? 2 : this.selected.length
            const all_buttons = this.buttons.length == 0 ? [] : this.buttons[type_actions] || []
            let temp = []

            all_buttons.forEach(button => {
                button.allow_access = this.checkUserAccessRights(button.rights)

                if(!button.condition) {
                    temp.push(button)
                }
                else {
                    const condition = button.condition

                    if(this[condition](this.getPartialSelected())) {
                        temp.push(button)
                    }
                }
            })

            return temp
        },

        primaryButtons() {
            return this.buttons_enabled.filter(btn => btn.className.includes("btn-primary"))
        },

        hasPreviewColumn() {
            return this.previewColumn !== null && typeof(this.previewColumn) == 'function'
        },

        columnQuantity() {
            return this.fields.length
        },

        userAccess() {
            return this.$store.state.userAccess
        },

        /* Les colonnes à afficher */
        fields: function() {
            let fields = []
            const view = this.selectedView['value']
            const max_columns = this.isBelowMd ? view['mobile_columns'] : view['columns'].length

            /* Afficher éventuellement les cases à cocher */
            if(this.checkboxes !== undefined && this.checkboxes === true)
                fields.push({ key: '[checkbox]', label: '', tdClass: 'td_checkbox' })
            else
                fields.push({ key: '[fake-checkbox]', label: '', tdClass: 'td_checkbox' })

            //Si j'ai des colonnes a jouter dynamiquement au début
            if(this.columsAddBegin){
                for(let index in this.columsAddBegin) {
                    fields.push({ key: this.columsAddBegin[index], label: this.getTrad('table.'+this.id_table+'.'+this.columsAddBegin[index]), tdClass: [] })
                }
            }

            /* Traiter chaque colonne */
            for(let index = 0; index < this.columns.length; index++) {
                if (index >= max_columns) {
                    break
                }

                let options = {
                    formatter: this.$options.filters.prettyField
                }

                // Lorsqu'un attribut indent est sur un item on applique un padding sur la première colonne
                if (index === 0) {
                    options.tdAttr = (value, key, item) => {
                        return {
                            style: `padding-left: ${10 + (25 * (item.indent || 0)) }px;`
                        }
                    }
                    options.tdClass = (value, key, item) => {
                        if (item.indent) {
                            return 'td-indent'
                        }
                    }
                }

                fields.push(Object.assign(this.columns[index], options))
            }

            if(this.switch === true)
                fields.push({ key: '[switch]', label: '', tdClass: 'td_switch' })


            //Si j'ai des colonnes a jouter dynamiquement
            if(this.columsAdd){
                for(let index in this.columsAdd) {
                    fields.push({ key: this.columsAdd[index], label: this.getTrad('table.'+this.id_table+'.'+this.columsAdd[index]), tdClass: [] })
                }
            }


            // Afficher la colonne de prévisualisation, s'il y en a une
            if(this.hasPreviewColumn)
                fields.push({ key: '[preview]', label: this.getTrad('global.apercu'), tdClass: ['previewColIdentifier', 'previewColIdentifier__default'] })


            if (this.isBelowMd) {
                fields.push({ key: 'show_details', label: '', td_class: 'td_details', sortable: false })
            }



            return fields
        },

        /* Calcule la liste des groupes de table (les entêtes) */
        tables_groups() {
            if(!this.group_by || !this.computed_items) {
                return []
            }

            // On récupère les attributs du group by
            let new_items = this.getArrayObjProperty(this.computed_items, this.group_by)

            if(this.group_by.endsWith('_date') && !['horse_pension'].includes(this.id_table)) {
                for(let i in new_items) {
                    let item = new_items[i]
                    const date_lang_format = Vue.prototype.$i18n.locale()
                    if (item instanceof Date || typeof item === 'string') {
                        const date_formatted = Date.parseTz(item).toLocaleDateString(date_lang_format)
                        if(date_formatted !== 'Invalid Date') {
                            item = date_formatted
                        }
                    }
                    new_items[i] = item
                }
            }
            else {
                if(this.groupByCustom !== undefined && Object.prototype.hasOwnProperty.call(this.groupByCustom, this.group_by)) {
                    new_items = new_items.map(item => this.groupByCustom[this.group_by](item))
                }
            }

            // On enlève les doublons
            return this.cleanDuplicatArray(new_items)
        },

        tables_groups_brut() {
            if(!this.group_by || !this.computed_items) {
                return []
            }

            return this.cleanDuplicatArray(this.getArrayObjProperty(this.computed_items, this.group_by))
        },

        /* Calcul un tableau d'objets de ligne d'entête */
        computed_head_lines: function() {
            let heads = []

            for (let i in this.tables_groups) {
                heads.push({ '[group_by]': this.tables_groups[i], isActive: false, _rowVariant: 'secondary' })
            }

            return heads
        },

        /* Données des tables, versions groupées. Retourne un array, avec pour clé le groupe, et pour valeur les lignes de la sous table */
        computed_items_group: function() {
            let arr_result = {}
            let total = {}

            // Pour chaque groupe, on créé un tableau d'items
            for (let i in this.tables_groups) {
                let first = true
                let index = this.tables_groups[i]
                const items_group_by = this.computed_items.filter(item => {
                    let el = this.getNestedObjectString(item, this.group_by)
                    if(this.group_by.endsWith('_date') && !['horse_pension'].includes(this.id_table)) {
                        const date_lang_format = Vue.prototype.$i18n.locale()
                        if (el instanceof Date || typeof el === 'string') {
                            const date_formatted = Date.parseTz(el).toLocaleDateString(date_lang_format)
                            if(date_formatted !== 'Invalid Date') {
                                el = date_formatted
                            }
                        }
                    }
                    else {
                        if(this.groupByCustom !== undefined && Object.prototype.hasOwnProperty.call(this.groupByCustom, this.group_by)) {
                            el = this.groupByCustom[this.group_by](el)
                        }
                    }

                    return el == index
                })

                if(items_group_by.length === 0) {
                    continue
                }
                arr_result[index] = this.computed_head_lines.filter(head => {
                    return (head['[group_by]'] || head['[group_by]'] == '') && head['[group_by]'] == index 
                })
                arr_result[index] = arr_result[index].concat(items_group_by)
                arr_result[index][0]['[group_by]'] = index

                //Pour chaque item de type particulier dans le tableau
                if (this.views[0].value['columns_types'] && this.views[0].value['columns_types']['types'] != null){
                    
                    for (const [key, value] of Object.entries( this.views[0].value['columns_types']['types'])) {
                        if(Object.values(this.views[0].value['columns']).includes(key)) {
                            // Item de type monnaie
                            const items_group_by = this.computed_items.filter(item => this.getNestedObjectString(item, this.group_by) == index)
                            if (value === "monnaie"){
                                total = {}
                                const currencyArray = []
                                for (const item of items_group_by){
                                    let formatCurrency = this.getNestedObjectString(item, this.views[0].value['columns_types']['currency'])
                                    if (currencyArray.includes(formatCurrency)){
                                        if(item[key]){
                                            total[formatCurrency] = total[formatCurrency] + item[key]
                                        }
                                    } 
                                    else {
                                        currencyArray.push(formatCurrency)
                                        if(item[key]){
                                            total[formatCurrency] = item[key]
                                        }
                                    }
                                }  
                                if (first){
                                    arr_result[index][0]['[group_by]'] += ' ('
                                    first = false
                                }
                                for (const currency of currencyArray){
                                    if(total[currency] === undefined){
                                        total[currency] = 0
                                    }
                                    arr_result[index][0]['[group_by]'] += this.getTrad('table.'+this.id_table+'.'+key)+' '+currency+': '+total[currency].toFixed(2) +', '
                                }
                                arr_result[index][0]['[group_by]'] = arr_result[index][0]['[group_by]'].slice(0, -2)
                                arr_result[index][0]['[group_by]']+= ' // '
                            }
                            //Pour que le groupe_by fonctionne il faut avoir des valeur Int ou Float
                            else if (value === "number"){
                                total = 0
                                for (const item of items_group_by){
                                    if(item[key]){
                                        total = total + item[key]
                                    }
                                } 
                                if (first){
                                    arr_result[index][0]['[group_by]'] += ' ('
                                    first = false
                                }
                                arr_result[index][0]['[group_by]'] += this.getTrad('table.'+this.id_table+'.'+key)+': '+total +', '
                            }
                        }
                    }
                    if (first === false){
                        arr_result[index][0]['[group_by]'] = arr_result[index][0]['[group_by]'].slice(0, -4)
                        arr_result[index][0]['[group_by]'] += ' )'
                    }                
                }
            }
            return arr_result
        },

        /* Vérifie si on affiche une vue utilisateur ou par défaut */
        isUserView: function() {
            return this.selectedView !== null && this.selectedView.id != 0
        },

        /* Détermine si le user a spécifié de quoi construire une url avec router-link. Si non, on utilise item.href */
        autoConstructUrl() {
            if(!this.hrefsRoutes || this.hrefsRoutes.length === 0) return false

            // On regarde si on a un nom de route de spécifié
            const keys = Object.keys(this.hrefsRoutes)
            if(keys.length > 0) return false

            return true
        },

        /* Liens de colonnes calculés à partir de la props hrefsRoutes */
        liens: function() {
            if(!this.hrefsRoutes) return {}

            let liens_construct = {}
            const keys = Object.keys(this.hrefsRoutes)

            for(let i=0; i < keys.length; i++) {
                const index = 'cell('+keys[i]+')'
                liens_construct[index] = this.hrefsRoutes[keys[i]]
                liens_construct[index].column = keys[i]
            }

            return liens_construct
        },

        /* Formattage des colonnes à afficher en raw, à partir de la props rawColumns */
        rawColumnsComputed() {
            if(!this.rawColumns || this.rawColumns.length === 0) return []
            let result = []

            this.rawColumns.forEach(column => {
                const cell_index = 'cell('+column+')'

                result[cell_index] = {
                    rawHtml: true
                }
            })

            return result
        },

        externSlotColumnsComputed() {
            if(!this.externSlotColumns || this.externSlotColumns.length === 0) return []
            let result = []

            this.externSlotColumns.forEach(column => {
                const cell_index = 'cell('+column+')'

                result[cell_index] = {
                    externSlot: true
                }
            })

            return result
        },

        /* Formattage des colonnes ayant des callback, à partir de la prop colCallback */
        colCallbackComputed() {
            if(!this.colCallback || this.colCallback.length === 0) return {}
            let result = {}

            // L'objet a une propriété du nom de la colonne à callback, et une valeur pour cette propriété, qui est la callback
            Object.keys(this.colCallback).forEach(column => {
                const cell_index = 'cell('+column+')'

                // On injecte la callback
                result[cell_index] = {
                    callback: this.colCallback[column]
                }
            })

            return result
        },

        /* Formattage des colonnes servant de preview, à partir de la prop customPreviewColumn */
        customPreviewColumnComputed() {
            if(!this.customPreviewColumn || this.customPreviewColumn.length === 0) return {}
            let result = {}

            // L'objet a une propriété du nom de la colonne à callback, et une valeur pour cette propriété, qui est la callback
            this.customPreviewColumn.forEach(column => {
                const cell_index = 'cell('+column+')'

                result[cell_index] = {
                    preview: true
                }
            })

            return result
        },

        /* La liste des colonnes dont le slot doit être modifié. Assemble liens, rawColumnsComputed, colCallbackComputed et customPreviewColumnComputed */
        rowToEditSlots() {
            let result = this.deppCloneObj(this.liens)
            result = _merge(result, this.colCallbackComputed)
            result = _merge(result, this.rawColumnsComputed)
            result = _merge(result, this.customPreviewColumnComputed)
            result = _merge(result, this.externSlotColumnsComputed)

            return result
        },

        localBusy: {
            get() {
                return this.busy
            },
            set(val) {
                this.$emit('update:busy', val)
            }
        },

        switchComputed() {
            return this.switch
        },

        /* Sync - liste des modèles en cours de traitement */
        syncModelsWorking() {
            return this.$store.state.sync.progressModels
        },

        /* Sync - retourne true si le modèle du transformer est en cours de synchro */
        synchroIsWorkingOnCurrentModel() {
            if(!this.transformer) return false
            return this.syncModelsWorking.indexOf(this.transformer.table) > -1
        },

        exportFields() {
            let fields = this.getExportFields()

            let fields_formatted = {}
            for(let i in fields) {
                fields_formatted[fields[i]] = i
            }

            return fields_formatted
        },

        showCheckAllTip() {
            return this.transformer === undefined && this.isCheckedAll
        }
    },
    watch: {
        /* Si il y a des lignes à préselectionner, mettre cela en cache */
        async preselected_items() {
            this.preselected_lines = this.preselected_items
            this.preselectThings()
        },

        /* Mettre à jour la vue si elle est forcée par le parent */
        force_view: function(val) {
            this.selectedView = val
        },

        selectedView: function(val) {
            this.group_by = val.group_by || ''
            this.loadView()
        },

        switched: function(val) {
            if(this.callback_switch) {
                this.callback_switch(val)
            }
        },

        pageItems() {
            this.updateComputedItems()
        },

        items() {
            this.updateComputedItems()
        },

        computed_items() {
            this.preselectThings()
            this.updateSelected()

            if (!this.isFetching && this.display_mode === null && this.computed_items.length) {
                this.display_mode = this.computed_items.length > 5 ? 'list' : 'grid'
            }
        },

        // isCheckedAll(val) {
        //     this.setCheckedAll(val)
        // },

        baseFilters() {
            this.updateFilters()
        },

        elementsPerPageModel(val){
            this.ctx.perPage = val

            this.setConfig("table-" + this.id_table, val)
        },

        /* Tous les changements qui peuvent demandent un update du tableau */
        ctx: {
            handler() {
                // On debounce le refresh parce qu'il est appelé 2 fois au changement du tri
                // 1 fois pour le sortBy et 1 fois pour le sortDesc
                this.debouncedRefreshTable()
            },
            deep: true
        },

        dateFilter: {
            handler() {
                this.updateDateRange()
            },
            deep: true
        },

        synchroIsWorkingOnCurrentModel() {
            this._refreshTable()
        },

        '$route' (to, from) {
			if(this.id_table !== this.views[0].name) {
				this.init_view()
			}
		}
    }
}
</script>
