export default {
	"contract_client_area":{
      0:[
         {
            "name":"ajouter_contract",
            "action":"goToAddContract",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary btn-lg",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"imprimer",
            "action":"goToPrintContract",
            "spin":false,
            "icon":["fas","download"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajouter_contract",
            "action":"goToAddContract",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary btn-lg",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "contract_mare_client_area":{
      0:[
         {
            "name":"ajouter_contrat",
            "action":"goToAddContract",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary btn-lg",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"imprimer",
            "action":"goToPrintContract",
            "spin":false,
            "icon":["fas","download"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajouter_contrat",
            "action":"goToAddContract",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary btn-lg",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "expeditions_clientarea":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"demande_traitee",
            "action":"goToConfirmOpen",
            "spin":true,
            "icon":["fal","envelope"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"demande_traitee",
            "action":"goToConfirmOpen",
            "spin":true,
            "icon":["fal","envelope"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "horse_clientarea":{
      0:[
         {
            "name":"ajouter_horse",
            "action":"goToAddHorse",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary btn-lg",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"hide",
            "action":"goToHideHorse",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajouter_horse",
            "action":"goToAddHorse",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary btn-lg",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"hide",
            "action":"goToHideHorse",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajouter_horse",
            "action":"goToAddHorse",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary btn-lg",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "addressed_invoices_to_pay":{
      0:[
         {
            "name":"online_paiement",
            "action":"goToSelectInvoiceToPayAndExec",
            "spin":false,
            "icon":["far","credit-card"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":[
               "hasPaymentPayment"
            ],
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"imprimer",
            "action":"goToPdfInvoice",
            "spin":true,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"online_paiement",
            "action":"goToPayOnline",
            "spin":false,
            "icon":["far","credit-card"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":[
               "hasPaymentPayment"
            ],
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"imprimer",
            "action":"goToPdfInvoice",
            "spin":true,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"online_paiement",
            "action":"goToPayOnline",
            "spin":false,
            "icon":["far","credit-card"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":[
               "hasPaymentPayment"
            ],
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "addressed_payment":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "addressed_invoices_sold":{
      0:[
         {
            "name":"",
            "action":"",
            "spin":false,
            "icon":[],
            "className":"d-none",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"imprimer",
            "action":"goToPdfInvoice",
            "spin":false,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"imprimer",
            "action":"goToPdfInvoice",
            "spin":false,
            "icon":["far","file-pdf"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
   "horse_actes":{
      0:[
         {
            "name":"ajouter",
            "action":"goToAddActe",
            "spin":false,
            "icon":["far","plus"],
            "className":"btn-primary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      1:[
         {
            "name":"modifier",
            "action":"goToEditActe",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteActe",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"acte.suppression",
               "text_question":"acte.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"ajouter_note",
            "action":"goToAddActNote",
            "spin":false,
            "icon":["far","sticky-note"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"analyse",
            "action":"goToAnalyse",
            "spin":false,
            "icon":["fal","file-medical"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"dupliquer",
            "action":"goToDupliqueActe",
            "spin":false,
            "icon":["far","clone"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ],
      2:[
         {
            "name":"modifier",
            "action":"goToEditActe",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"supprimer",
            "action":"goToDeleteActe",
            "spin":false,
            "icon":["far","trash-alt"],
            "className":"btn-secondary",
            "modal_options":{
               "text_title":"acte.suppression",
               "text_question":"acte.confirmation_suppression",
               "text_message":"general.avertissement_suppression"
            },
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"modifier_lot",
            "action":"goToEditActeLot",
            "spin":false,
            "icon":["far","pencil-alt"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         },
         {
            "name":"analyse",
            "action":"goToAnalyse",
            "spin":false,
            "icon":["fal","file-medical"],
            "className":"btn-secondary",
            "modal_options":null,
            "condition":null,
            "rights":"MVP",
            "dropdown_name":null
         }
      ]
   },
}